
import React from "react"
import { graphql } from "gatsby"
import Seo from "../components/seo"
import { Box } from "@mui/system"

import './../styles/dialogi-kazalo.scss'
import DialogiSinglePage from "../components/dialogi/DialogiSinglePage";


const DialogiSingle = ({pageContext, data: rowData}) => {
  
  // console.log(pageContext)

  const data = rowData.dialogi
  // console.log(data)

  return (
    <>
      <Seo title={`Dialogi ${data.stevilka} ${data.leto.year}`} />
      <DialogiSinglePage data={data}>
        {/* <Typography variant='h3' mb={9}>Kazalo</Typography> */}
        <Box 
          className="d-kazalo"
          sx = { (theme) => ({
            // columnGap: {
            //   md: theme.spacing(8),
            //   lg: theme.spacing(10)
            // },
            // columnCount: {
            //   md: 2,
            //   // lg: 3
            // },
          })}
          dangerouslySetInnerHTML={{__html: data.kazalo.data.kazalo}} />
      </DialogiSinglePage>
    </>
  )
  
}

export default DialogiSingle

export const query = graphql`
  query($id: Int){
    dialogi: strapiDialogi(strapi_id: {eq: $id}) {
      strapi_id
      id
      stevilka
      title
      leto {
        color
        year
        number
        textColor
      }
      description: childStrapiDialogiDescriptionTextnode {
        html: description
      }
      kazalo {
        data {
          kazalo
        }
      }
      prices {
        regular
        online
        discount
      }
      cover {
        localFile {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 1024)
          }
        }
      }
      pdf {
        url
      }
    }
  }
`